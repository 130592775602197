import React, { useEffect } from 'react';
import { useGetRouletteStatus, useGetSounds, useGetSpeedType } from 'games/CaseGame/hooks';
import { CaseEnum } from 'games/CaseGame/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openCaseTypeAction } from 'games/CaseGame/ducks';
import { Roulette, LineRoulette } from '../Roulette';
import { Free } from 'games/CaseGame/interfaces';
import { isEqual } from 'lodash';
// import { useGetLuckyWonData } from 'modules/event-module/comics-event/hooks';
// import { DIALOG_EVENT_CONFIG } from 'modules/event-module/comics-event';
// import { actionClearRareCaseInfo } from 'modules/event-module/comics-event/duck/default-event-duck';
// import { TicketTypeEnum } from 'modules/event-module/comics-event';
// import { useDialog } from 'core/hooks';

interface RouletteDecorator {
  caseId: string;
  type: string;
  caseType: CaseEnum;
  free?: Free;
  categoryId: number;
  // ticketData: {
  //   ticketsAmount: number;
  //   ticketColor: TicketTypeEnum;
  // } | null;
}

export const RouletteDecorator: React.FC<RouletteDecorator> = React.memo(
  ({ type, caseId, free, categoryId, caseType /* ticketData */ }) => {
    const { isAllInRoll, isAllAfterRoll, isAllStartRoll } = useGetRouletteStatus();
    const { speedType } = useGetSpeedType();
    const { play, playEnd, stop, playStart } = useGetSounds(type, speedType);
    // const data = useGetLuckyWonData();
    // const { handleToggleDialog } = useDialog(DIALOG_EVENT_CONFIG.RARE_CASE);
    //NOTE only for event
    const history = useHistory();
    const dispatch = useDispatch();
    const search = new URLSearchParams(history.location.search).get('uuid');
    const size = window.innerWidth / 2;

    useEffect(() => {
      return () => {
        dispatch(openCaseTypeAction('demo'));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   return () => dispatch(actionClearRareCaseInfo());
    // }, [dispatch]);

    useEffect(() => {
      if (isAllStartRoll) {
        playStart();
      }
      if (isAllInRoll) {
        play();
      }
      // if (data && isAllAfterRoll) {
      //   handleToggleDialog(DIALOG_EVENT_CONFIG.RARE_CASE, {
      //     caseId: data.caseId,
      //   })();
      // }
      if (isAllAfterRoll) {
        stop();
        playEnd();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllInRoll, isAllAfterRoll, isAllStartRoll]);

    return (
      <>
        <Roulette
          isFreeFieldExist={!!free?.count}
          caseId={caseId}
          search={search}
          caseType={caseType}
          categoryId={categoryId}
          //NOTE: Added for event-summertime
          // ticketData={ticketData}
        />
        <LineRoulette
          data-roulette-status={isAllAfterRoll ? 'afterRoll' : 'notAfterRoll'}
          left={size}
          id="line"
        />
      </>
    );
  },
  isEqual
);
