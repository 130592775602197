import { IGameModules } from 'core/rootInterfaces/root.interface';
import { faqModule as faq } from './FAQ';
// import { faucetModule as faucet } from './faucet';
import { refillModule as refill } from './Refill';
import { notificationModule as notifications } from './Notifications';
import { personalAreaModule as personalArea } from './PersonalArea';
import { leadersRaceNewModule as leaders } from './LeadersRace';
import { lobbyModule as lobby } from './Lobby';
import { assistanceModule as assistance } from './Assistance';
import { privacyPolicyModule as privacyPolicy } from './PrivacyPolicy';
import { tosModule as tos } from './TOS';
import { accountsModule as accounts } from './Accounts';
// import { promoModule as promo } from './promo'
import { withdrawalModule as withdrawal } from './withdrawal';
import { marketplaceModule as marketplace } from './Marketplace_old';
import { marketplace as marketplace_new } from './marketplace';
import { inventory } from './inventory';
// import { eventModule } from './event-module';

const modules: IGameModules = {
  faq,
  // faucet,
  refill,
  withdrawal,
  marketplace,
  marketplace_new,
  inventory,
  notifications,
  personalArea,
  leaders,
  assistance,
  accounts,
  // promo,
  lobby,
  privacyPolicy,
  tos,
  // eventModule,
};

export default modules;
