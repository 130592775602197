import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ActionBlock /* EventLink */ } from './BalanceActions.styles';
// import { Modules } from 'core/interfaces';
// import { Link } from 'react-router-dom';

export const BalanceActions = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <EventLink to={Modules.EVENT} as={Link}>
        <div>
          <span>Comics Heroes</span>
        </div>
      </EventLink> */}
      <Container>
        <ActionBlock to="/withdrawal/method">
          <i className="icon-minus-circle" />
          <span>{t('Withdraw')}</span>
        </ActionBlock>
        <ActionBlock to="/refill">
          <i className="icon-plus-circle" />
          <span>{t('Refill')}</span>
        </ActionBlock>
      </Container>
    </>
  );
};
