import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { NavigationLink } from '@components/Common';

//NOTE: event related staff
// export const EventLink = styled(NavigationLink)`
//   background: var(--color-dark-600);
//   margin-bottom: 28px;

//   & div:not(.leading) > span {
//     color: #6dad52;
//     font-size: 14px;
//     font-weight: 700;
//     letter-spacing: 0.2px;
//   }

//   & div:not(.leading)::after {
//     color: var(--color-white);
//   }
// `;

export const Container = styled.div`
  align-items: center;
  background-color: var(--gray-400);
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  height: 48px;
  justify-content: space-around;

  & > a:first-child {
    color: var(--secondary);
  }

  & > a:last-child {
    color: var(--yellow);
  }
`;

export const ActionBlock = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  letter-spacing: 0.11px;
  line-height: 2.2;

  & > span {
    margin-left: 0.5rem;
  }

  & > i::before {
    color: inherit !important;
    font-size: 1.2rem;
  }
`;
