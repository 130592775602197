import { createContext } from 'react';
import { Modules, routerAction, routerReducer } from 'core/interfaces';
import { RefillRoute } from 'modules/Refill';
import { MarketplaceRouter } from 'modules/marketplace';
import { InventoryRouter } from 'modules/inventory';
// import { FaucetRoute } from 'modules/faucet';
import { WithdrawalRouter } from 'modules/withdrawal';
import { ProfileRouter } from 'modules/PersonalArea';
import { ClassicRouter } from 'games/ClassicGame';
import { HiloRouter } from 'games/Hilo';
import { CrashRouter } from 'games/CrashGame';
import { FastRouter } from 'games/Fast';
import { DoubleRouter } from 'games/double';
import { X50Router } from 'games/x50';
import { BaccaratRouter } from 'games/Baccarat';
import { WheelRouter } from 'games/FortuneWheel';
import { TowerRouter } from 'games/TowerGame';
import { CasesRouter } from 'games/CaseGame';
import { SlotsRouter } from 'games/slots';
// import { CraftRouter } from 'games/CraftGame'
import { PoggiRouter } from 'games/Poggi';
import { ChatRouter } from 'modules/Chat';
import { CaseBattleRouter } from 'games/CaseBattle';
// import { EventRouter } from 'modules/event-module';

export const MODULES = new Map<Modules, any>([
  [Modules.CLASSIC, ClassicRouter],
  [Modules.FAST, FastRouter],
  [Modules.HILO, HiloRouter],
  [Modules.CRASH, CrashRouter],
  [Modules.DOUBLE, DoubleRouter],
  [Modules.X50, X50Router],
  [Modules.BACCARAT, BaccaratRouter],
  [Modules.WHEEL, WheelRouter],
  [Modules.TOWER, TowerRouter],
  [Modules.CASES, CasesRouter],
  [Modules.SLOT, SlotsRouter],
  // [Modules.CRAFT, CraftRouter],
  [Modules.POGGI, PoggiRouter],
  [Modules.REFILL, RefillRoute],
  [Modules.MARKET, MarketplaceRouter],
  // [Modules.EVENT, EventRouter],
  [Modules.INVENTORY, InventoryRouter],
  // [Modules.FAUCET, FaucetRoute],
  [Modules.WITHDRAWAL, WithdrawalRouter],
  [Modules.PERSONAL, ProfileRouter],
  [Modules.CHAT, ChatRouter],
  [Modules.CASE_BATTLE, CaseBattleRouter],
]);

export const asyncModules = (state: routerReducer, action: routerAction) => {
  switch (action.type) {
    case 'set': {
      const { name, module } = action;
      return state.set(name, module);
    }
    default: {
      throw new Error();
    }
  }
};

export const RouterContext = createContext(null);
