import React, { FC /* , useEffect, useMemo, useState */ } from 'react';
import { Container } from './CaseList.styles';
import { CaseListItem, EmptyList } from './components';
import { useGetCaseListData /* useWindowSize */ } from 'games/CaseGame/hooks';
import { CaseListItemContainer } from '@components/CaseListItemDefault';
import { CaseData } from 'games/CaseGame/interfaces';
// import { CaseListEventBanner } from 'modules/event-module';

const empty = new Array(40).fill(0).map((_, index) => index);

export const CaseList: FC = React.memo(() => {
  const { casesData } = useGetCaseListData();
  // const { width } = useWindowSize();
  // const [columnsCount, setColumnsCount] = useState(3);

  // useEffect(() => {
  //   const getColumnsCount = width < 500 ? 3 : width < 650 ? 4 : 5;
  //   setColumnsCount(getColumnsCount);
  // }, [width]);

  // const updatedCaseList = useMemo(() => {
  //   if (
  //     casesData.isLoading ||
  //     !casesData.data.caseList.length ||
  //     casesData.data.idCategory !== 'all'
  //   ) {
  //     return null;
  //   }
  //   const newCaseList = [...casesData.data.caseList];
  //   const middleIndex = Math.ceil(newCaseList.length / 9);
  //   let x = middleIndex;
  //   while (x % columnsCount !== 0) x++;
  //   return [newCaseList.slice(0, x), newCaseList.slice(x)];
  // }, [casesData, columnsCount]);

  if (casesData.isLoading) {
    return (
      <Container className="tablet">
        {empty.map((value: number) => (
          <CaseListItemContainer className="skeleton" key={value} />
        ))}
      </Container>
    );
  }

  if (!casesData.data.caseList.length) {
    return <EmptyList />;
  }

  return (
    // <>
    //   {updatedCaseList ? (
    //     <>
    //       {renderCaseList(updatedCaseList[0])}
    //       <CaseListEventBanner />
    //       {renderCaseList(updatedCaseList[1])}
    //     </>
    //   ) : (
    renderCaseList(casesData.data.caseList)
    //   )}
    // </>
  );
});

const renderCaseList = (caseList: CaseData[]) => (
  <Container className="tablet">
    {caseList.map(data => (
      <CaseListItem className="item-little-screen" key={data.id} caseData={data} />
    ))}
  </Container>
);
